import type { RunningPhase } from '@/app/phases/RunningPhase/RunningPhase'
import {
  THREE,
  AnimationsManager,
  game,
  fpsManager,
  playersManager,
  gsap,
  corePhasesManager,
  CorePhases,
  minigameConfig,
  type PlayerInfo,
  modes
} from '@powerplay/core-minigames'
import {
  gameConfig,
  animationsConfig,
  trainingConfig
} from '../../config'
import { disciplinePhasesManager } from '../../phases/DisciplinePhasesManager'
import {
  DisciplinePhases,
  type StartPositionsData,
  Sides,
  PlayerAnimationsNames,
  TutorialObjectiveIds,
  Tasks,
  TutorialEventType
} from '../../types'
import { DownhillManager } from './DownhillManager'
import { HeartRateManager } from './HeartRateManager'
import { AthleteAnimationManager } from './AthleteAnimationManager'
import { UphillManager } from './UpHIllManager'
import { SpeedBarMaxValueManager } from './SpeedBarMaxValueManager'
import { HillLinesCreator } from '../hill/HillLinesCreator'
import { SpeedManager } from '@/app/SpeedManager/SpeedManager'
import {
  actionButtonState,
  gamePhaseState,
  startPhaseState
} from '@/stores'
import type { OpponentVelocityManager } from '../opponent/OpponentVelocityManager'
import type { PlayerVelocityManager } from '../player/PlayerVelocityManager'
import { tutorialObjectives } from '@/app/modes/tutorial/TutorialObjectives'
import {
  checkpointManager,
  trainingTasks
} from '@/app/modes/training'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'

/**
 * Trieda pre atleta
 */
export class Athlete {

  /** 3D objekt lyziara - cela scena */
  public athleteObject = new THREE.Object3D()

  /** Zakladny animacny manager */
  public animationsManager!: AnimationsManager

  /** Manager pre animacne stavy */
  public athleteAnimationManager = new AthleteAnimationManager(this)

  /** Manazer rychlosti */
  public maxSpeedBarManager = new SpeedBarMaxValueManager()

  /** Manager pre tep srdca */
  public heartRateManager = new HeartRateManager()

  /** Manager rychlosti */
  public velocityManager!: PlayerVelocityManager | OpponentVelocityManager

  /** Manager povolenia ist do downhillu */
  private downhillStateManager = new DownhillManager(this)

  /** Manager povolenia ist do upHillu */
  private uphillStateManager = new UphillManager(this)

  /** manager rychlosti */
  public speedManager = new SpeedManager()

  /** hrac je skrceny */
  public isTuck = false

  /** hrac je sprinting */
  public isSprinting = false

  /** hrac sa rozbieha */
  public isStartRun = false

  /** hrac skatuje */
  public setStartSkating = false

  /** hrac uz dokoncil rozbeh a ide normalne */
  public isSkating = false

  /** hrac je starting */
  public isStarting = false

  /** hrac je vo faze lunge pred cielom */
  public isLunge = false

  /** True ak prave meni drahu */
  public isChangingPath = false

  /** hrac je na konci */
  public isEnd = false

  /** Ci je aktivne aktualizovanie pohybovych animacii */
  public activeUpdatingMovementAnimations = false

  /** Posledna pozicia atleta */
  public lastAthletePosition = new THREE.Vector3()

  /** dolezity manager na pohyb */
  public hillLinesManager!: HillLinesCreator

  /** Ci je aktivny rozbeh */
  public startRunActive = false

  /** zavetrie */
  public isInSlipStream = false

  /** Timer pre rozbeh */
  private startRunTimer = 0

  /** Ci ide o hratelneho atleta alebo nie */
  public playable = false

  /** hrac za ktorym je moc blizko */
  public inSafeZoneOfAthlete: Athlete | undefined

  /** Atlet za ktorym som, ak som v slipstreame */
  public athleteOfSlipStream?: Athlete

  /** finish time hraca */
  public finishTime = minigameConfig.dnfValue

  /** aktulany split time ku ktoremu sa blizim */
  public actualSplitIndex = 0

  /** Je v zone za hracom, kde sa vypina sprint */
  public inSprintOffZone = false

  /** vypocitana attrStrength, aby sa nepocitala stale dookola */
  public attrStrength = 0

  /** casovac po rozbehu */
  public afterStartRunTween?: gsap.core.Tween

  /**
   * Konstruktor
   * @param uuid - UUID spera
   */
  public constructor(public uuid = '') {}

  /**
   * Getter pre aktualne percento na drahe
   * @returns - actualPercent
   */
  public getActualPercentOnPath(): number {

    return this.hillLinesManager.getActualPercent()

  }

  /**
   * Vratenie max hodnoty speed baru
   * @returns - heart rate max
   */
  public getSpeedBarMaxValue(): number {

    return this.maxSpeedBarManager.getSpeedBarMaxValue()

  }

  /**
   * Zistenie, ci sa ide dole kopcom
   * @returns True, ak sa ide dole kopcom
   */
  public isDownhillEnabled(): boolean {

    return this.downhillStateManager.getDownhillAllowed()

  }

  /**
   * Zistenie, ci sa ide hore kopcom
   * @returns True, ak sa ide hore kopcom
   */
  public isUphillEnabled(): boolean {

    return this.uphillStateManager.getUphillAllowed()

  }

  /**
   * Vratenie rychlostneho gradientu kopca
   * @returns Gradeitn
   */
  public getVelocityGradient(): number {

    return this.velocityManager.lastGradient

  }

  /**
   * Ci mozeme ist do downhillu
   * @returns - ci moze downhill
   */
  public isDownhillAllowed(): boolean {

    return this.downhillStateManager.getDownhillAllowed()

  }

  /** manualne povolenie downhillu */
  public allowDownhill(): void {

    this.downhillStateManager.allowDownhill()

  }

  /**
   * Vratenie objektu atleta
   * @returns Objekt atleta
   */
  protected getObject(): THREE.Object3D {

    // mechanika bude v zdedenej classe
    return new THREE.Object3D

  }

  /**
   * Vytvorenie lyziara
   * @param startData - Startovacie data
   * @param athleteName - Meno alteta
   * @param nameForAnimations - Meno pre ziskanie animacii
   */
  public create(startData: StartPositionsData, athleteName: string, nameForAnimations: string): void {

    const athlete = playersManager.getPlayerById(this.uuid) as PlayerInfo

    this.playable = athlete.playable || false
    this.athleteObject = this.getObject()
    game.scene.add(this.athleteObject)

    // animacie
    this.animationsManager = new AnimationsManager(
      this.athleteObject,
      animationsConfig,
      game.animations.get(nameForAnimations),
      gameConfig.defaultAnimationSpeed,
      fpsManager
    )
    this.animationsManager.setDefaultSpeed(gameConfig.defaultAnimationSpeed)
    this.animationsManager.resetSpeed()

    // toto len aby fungovali uvodne nastavovacky, aj tak sa to potom poposuva
    const position = gameConfig.startPosition

    // threeJS Section
    this.athleteObject.position.set(position.x, position.y, position.z)
    this.athleteObject.name = athleteName

    console.log('atlet vytvoreny...', startData.pathIndex, startData.row)

    this.hillLinesManager = new HillLinesCreator(
      startData.pathIndex,
      startData.row,
      () => {

        this.isLunge = true

      },
      this
    )

    this.attrStrength = this.getAthleteAttributeStrength()
    console.log(`AttrStrength atleta ${this.uuid}: ${this.attrStrength}`)

    this.debugBehaviour()

  }

  /**
   * Debug AI
   */
  private debugBehaviour(): void {

    if (!gameConfig.debugAI) return

    gsap.to({}, {
      onComplete: () => {

        let sprintBarValue
        if (this.playable) {

          const velocityManager = this.velocityManager as PlayerVelocityManager
          sprintBarValue = velocityManager.playerSprintBar.getBarValue()

        } else {

          const velocityManager = this.velocityManager as OpponentVelocityManager
          sprintBarValue = velocityManager.opponentSprintBar.getBarValue()

        }
        console.log(
          `atlet: ${this.uuid},`,
          `sprint bar: ${sprintBarValue},`,
          `split stream: ${this.isInSlipStream ? 'ano' : 'nie'}`,
          `heart rate: ${this.heartRateManager.getHeartRate()}`
        )
        this.debugBehaviour()

      },
      duration: 5
    })

  }

  /**
   * Odstartovanie hraca
   */
  public start(): void {

    console.log('odstartoval atlet', this.uuid)
    this.launchStartAnimation()
    gamePhaseState().speedPower = this.velocityManager.getSpeedPowerState()
    this.speedManager.setActive(true)

    // spustime casovac pre koniec rozbehu vo frameoch
    this.startRunActive = true

  }

  /**
   * Aktualizovanie pozicie lyziara
   * @param positionObj - Pozicia
   */
  private updatePlayerPosition(positionObj: THREE.Object3D): void {

    this.athleteObject.position.copy(positionObj.position)

    const instalerp = [DisciplinePhases.start, DisciplinePhases.preStart].includes(disciplinePhasesManager.actualPhase)

    let t = 0.5
    if (instalerp) t = 1

    this.athleteObject.quaternion.slerp(positionObj.quaternion, t)

  }

  /**
   * Vratenie rotacie lyziara
   * @returns Quaternion lyziara
   */
  public getQuaternion(): THREE.Quaternion {

    return this.athleteObject.quaternion

  }

  /**
   * Vratenie pozicie atleta
   * @returns Pozicia atleta
   */
  public getPosition(): THREE.Vector3 {

    return this.athleteObject.position

  }

  /**
   * Kontrola dlzky rozbehu podla konfigu
   * @returns
   */
  private checkStartRun(): void {

    if (!this.startRunActive) return

    this.startRunTimer += 1
    if (this.startRunTimer >= gameConfig.startRunFrames) {

      this.startRunActive = false
      this.startRunTimer = 0

      // nastavime, ze rozbeh uz nie je a ma ist normalny skating po sekunde
      this.isStartRun = false

      if (this.playable) {

        startPhaseState().showBar = false
        actionButtonState().isStart = false

        console.log('skoncila faza run, za chvilku pojde skating')
        trainingTasks.saveTaskValue(Tasks.start, this.velocityManager.getSpeedPowerState() / 100)

      }

      this.afterStartRunTween = gsap.to({}, {
        onComplete: () => {

          this.setStartSkating = true
          this.isSkating = true
          this.velocityManager.checkStartPowerAfterStartRun(this.uuid)
          if (this.playable) {

            if (modes.isTutorial()) {

              if (this.velocityManager.getSpeedPowerState() > 60) {

                tutorialFlow.eventActionTrigger(TutorialEventType.goodStart)
                tutorialObjectives.passObjective(TutorialObjectiveIds.start as string)

              } else {

                tutorialFlow.eventActionTrigger(TutorialEventType.failedStart)
                if (tutorialFlow.failedStartCount < 1) return

              }

            }
            const runningPhase = disciplinePhasesManager
              .getDisciplinePhaseManager(DisciplinePhases.running) as RunningPhase
            runningPhase.showRunningUi()

          }

        },
        duration: 1
      })

    }

  }

  /**
   * Aktualizovanie hraca po vykonani fyziky
   */
  public updateAfterPhysics(): void {

    if (
      !corePhasesManager.isActivePhaseByType(CorePhases.discipline) ||
      disciplinePhasesManager.getActualPhase() === DisciplinePhases.end
    ) return

    this.maxSpeedBarManager.update(this.heartRateManager.getHeartRate(), this.playable)
    this.heartRateManager.update(this)
    this.velocityManager.update()

    this.downhillStateManager.update()
    this.uphillStateManager.update()

    this.athleteAnimationManager.update()

    // treningove veci
    if (this.playable && modes.isTrainingMode() && this.isSkating && !this.isEnd) {

      if (this.isInSlipStream) trainingTasks.countTaskValue(Tasks.slipstream, 1)

      checkpointManager.checkIfTriggered()

    }

  }

  /**
   * Aktualizovanie hraca pred vykonanim fyziky
   */
  public updateBeforePhysics(): void {

    if (corePhasesManager.isActivePhaseByType(CorePhases.discipline)) this.checkStartRun()

    const point = this.hillLinesManager.update()
    this.updatePlayerPosition(point)

  }

  /**
   * Aktualizovanie animacii hraca
   * @param delta - Delta
   */
  public updateAnimations(delta: number): void {

    this.animationsManager.update(delta)

  }

  /**
   * Spustenie animacie odrazenia na zaciatku
   */
  public launchStartAnimation = (): void => {

    this.isStarting = true

  }

  /**
   * Vypocet attrStrength
   * @returns attrStrength - number
   */
  public getAthleteAttributeStrength(): number {

    const attrStrength = 0.9
    if (this.playable || modes.isTutorial() || modes.isDailyLeague() || modes.isTournament()) return attrStrength

    const opponentTS = playersManager.getPlayerById(this.uuid)?.attribute.total || 0
    const playerTS = playersManager.getPlayerById(playersManager.getPlayer().uuid)?.attribute.total || 0

    let divider = 1

    if (playerTS > 1000) {

      divider = 1000

    } else if (playerTS > 100) {

      divider = 500

    } else {

      divider = 250

    }

    let result = attrStrength + (opponentTS - playerTS) / divider

    if (result < 0.7) result = 0.7
    if (result > 1.1) result = 1.1

    return result

  }

  /**
   * Zmena drahy hraca
   * @param side - strana zmeny drahy
   * @returns ci sa podarilo odbocit
   */
  public changePath(side: Sides): boolean {

    let validChange = false
    // ci sa moze menit draha (napr pri rozbehu sa nemoze)
    const canChange = this.isSkating && !this.isChangingPath

    if (canChange) {

      let animation = PlayerAnimationsNames.changeL
      let index = this.hillLinesManager.actualPathIndex + 1

      if (this.isTuck) {

        animation = PlayerAnimationsNames.downhillLeft

      }

      if (side === Sides.RIGHT) {

        animation = PlayerAnimationsNames.changeR
        index = this.hillLinesManager.actualPathIndex - 1

        if (this.isTuck) {

          animation = PlayerAnimationsNames.downhillRight

        }

      }

      this.athleteAnimationManager.changingPathAnimation = animation
      validChange = this.hillLinesManager.changePath(index)

    }

    if (this.playable && canChange && !validChange) {

      this.showBlockingEffect(side)

    }
    if (!validChange) {

      if (this.playable) {

        const runningPhase = disciplinePhasesManager
          .getDisciplinePhaseManager(DisciplinePhases.running) as RunningPhase
        runningPhase.switchLaneBlocker = false

      }

      return false

    }

    this.isChangingPath = true
    this.animationsManager.getAnimationAction(this.athleteAnimationManager.changingPathAnimation).paused = true
    if (this.playable) {

      tutorialFlow.eventActionTrigger(TutorialEventType.changePathSuccess)

    }
    return true

  }


  /**
   * Spravanie pri dosiahnuti ciela
   */
  public finishReached(): void {

    this.isEnd = true

    if (disciplinePhasesManager.getActualPhase() === DisciplinePhases.finish) return

    if (this.playable) {

      trainingTasks.saveTaskValue(
        Tasks.finish,
        trainingConfig.percentPerPosition[trainingTasks.position]
      )
      trainingTasks.saveLastTasksValues()
      console.warn(`Training tasks position: ${trainingTasks.position}`)

    } else {

      trainingTasks.countTaskValue(Tasks.finish, 1)
      console.warn(`Training tasks position: ${trainingTasks.position}, opponentid: ${this.uuid}`)

    }

  }

  /**
   * Zobrazenie blocking grafiky
   * @param _side - strana narazu
   */
  public showBlockingEffect(_side: Sides): void {

    // v potomkoch
    console.log(_side)

  }

  /**
   * reset atleta
   * @param startData - start positions data
   */
  public reset(startData: StartPositionsData = {
    row: 0,
    pathIndex: 0
  }): void {

    this.hillLinesManager.reset(startData)
    this.athleteAnimationManager.reset()
    this.animationsManager.resetAll()
    this.maxSpeedBarManager.reset()
    this.heartRateManager.reset()
    this.velocityManager.reset()
    this.downhillStateManager.reset()
    this.uphillStateManager.reset()
    this.speedManager.reset()
    this.isSprinting = false
    this.isStartRun = false
    this.setStartSkating = false
    this.isSkating = false
    this.isStarting = false
    this.isLunge = false
    this.isChangingPath = false
    this.isEnd = false
    this.activeUpdatingMovementAnimations = false
    this.lastAthletePosition = new THREE.Vector3()
    this.startRunActive = false
    this.isInSlipStream = false
    this.startRunTimer = 0
    this.inSafeZoneOfAthlete = undefined
    this.athleteOfSlipStream = undefined
    this.finishTime = minigameConfig.dnfValue
    this.actualSplitIndex = 0
    this.inSprintOffZone = false
    this.isTuck = false
    this.afterStartRunTween?.kill()

  }

}
